export const environment = {
  production: false,
  Source: "EKYCUI",
  API_ENDPOINT: "https://api.ekyc.engage-my-preprd.apac.bmw.cloud",
  BMW_Hostname: "ekyc-preprd.bmw.com.my",
  MINI_Hostname: "ekyc-preprd.mini.my",
  Motorrad_Hostname: "ekyc-preprd.bmw-motorrad.com.my",
  EngageBMW_URL: "https://engage-preprd.bmw.com.my/#/",
  EngageMini_URL: "https://engage-preprd.mini.my/#/",
  EngageMotorrad_URL: "https://engage-preprd.bmw-motorrad.com.my/#/",

  BMW_Hostname_Cloud: "bmw.ekyc.engage-my-preprd.apac.bmw.cloud",
  MINI_Hostname_Cloud: "mini.ekyc.engage-my-preprd.apac.bmw.cloud",
  Motorrad_Hostname_Cloud: "motorrad.ekyc.engage-my-preprd.apac.bmw.cloud",
  EngageBMW_Cloud_URL: "https://bmw.app.engage-my-preprd.apac.bmw.cloud/#/",
  EngageMini_Cloud_URL: "https://mini.app.engage-my-preprd.apac.bmw.cloud/#/",
  EngageMotorrad_Cloud_URL: "https://motorrad.app.engage-my-preprd.apac.bmw.cloud/#/",
  StaticFilePath: 'https://engage-my-preprd.apac.bmw.cloud/static',
  EnableChat: true,
  CCTChat: "https://cctapac.gc.azure.bmw.cloud/widgets-int/bmw/my-web/js/widgets.main.js",

  LocRocketId: "fibuxy/bmwengage_uat",
  //Show Messages
  Message_ImageUploaded: 'Image uploaded successfully. Please proceed to answer your Security Questions.',
  Message_OCRUnsuccessful: 'Verification unsuccessful. Please retry.',
  Generic_Error_Message: 'We are unable to proceed with your application. Please contact customer interaction centre at +603-27192688 for further assistance.',
  PendingSQ_Request_Message: 'Please complete your security question verification via your mobile phone.',
  PendingOCRSQ_Message: 'Please upload your identification document and complete security question via your mobile phone.',
  SendSMS_Wait_Message: 'Please wait for 60 seconds before resending the SMS.'
};